import * as React from "react"

const PageSection = ({ children, className, id, style }: { children: React.ReactNode, className?: string, id?: string, style?: React.CSSProperties }) => {
    return (
        <div id={id} className={`container-fluid px-4 px-md-0 py-4 ${className ?? ''}`} style={style}>
            <div className="row">
                <div style={{ height: "3rem" }}></div>
                <div className="col-lg-6 col-md-8 offset-0 offset-md-2 offset-lg-3">
                    {children}
                    <div style={{ height: "3rem" }}></div>
                </div>
            </div>
        </div>
    )
}

export default PageSection