// i18next-extract-mark-ns-start navigation
import * as React from "react"
import { Trans, Link, I18nextContext } from 'gatsby-plugin-react-i18next'
import LanguageSwitch from "./language-switch"


import { StaticImage } from 'gatsby-plugin-image'

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : { className: "nav-link" }
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Navbar = () => {
  const context = React.useContext(I18nextContext);

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary">
      <div className="container-fluid">

        <LanguageSwitch />

          <StaticImage
            src='../images/logos/light/wodna_warszawa_logo_light.png'
            layout="constrained"
            alt=""
            height={20}
            transformOptions={{ fit: `cover` }}
            formats={["auto", "webp", "avif"]}
            placeholder="blurred"
            className="ms-md-2"
          />
        

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-navbar"
          aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav ms-auto mb-2 mb-md-0">
            <li className="nav-item text-center">
              <ExactNavLink
                to="/"
              >
                <Trans>Aktywności</Trans>
              </ExactNavLink>
            </li>
            <li className="nav-item text-center">
              <ExactNavLink
                to="/flota"
              >
                <Trans>Flota</Trans>
              </ExactNavLink>
            </li>
            {/* <li className="nav-item text-center">
              <ExactNavLink
                to="/nadwislanska-rownonoc-2022"
              >
                Nadwiślańska Równonoc 2022
              </ExactNavLink>
            </li> */}
            {/* <li className="nav-item text-center">
              <ExactNavLink
                to="/swieto-wisly"
              >
                Święto Wisły 2022
              </ExactNavLink>
            </li> */}
            {/*
              (context.language == 'pl') &&
              <li className="nav-item text-center">
                <ExactNavLink
                  to="/szkoly"
                >
                  Dla Szkół
                </ExactNavLink>
              </li>*/
            }
          </ul>
        </div>
      </div>
    </nav>
  )
}


export default Navbar
