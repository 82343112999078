import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

const LanguageSwitch = () => {
    const { languages, originalPath,language } = useI18next();
    return (
        <div>
            <a className="nav-link dropdown-toggle text-reset" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {language.toUpperCase()}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {languages.map((lng) => (
                    <li key={lng}>
                        <Link className="dropdown-item" to={originalPath} language={lng}>
                            {lng.toUpperCase()}
                        </Link>
                    </li>
                ))}

            </ul>
        </div>
    );
};

export default LanguageSwitch;