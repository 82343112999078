import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../../components/layout';
import PageSection from '../../../components/page-section';
import Seo from '../../../components/seo';
const VistulaDayTicketsBookingPage = () => {
    return (
        <Layout>
            <Seo title={"Święto Wisły 2022 - Rezerwacja Biletów"} />
            <PageSection>

            </PageSection>
        </Layout>
    );
}

export default VistulaDayTicketsBookingPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;